@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .all-\[unset\] {
    all: unset;
  }
  .Input-custom {
    @apply self-stretch h-11 px-3 py-3.5 bg-white rounded-lg border border-zinc-200 justify-center items-center inline-flex;
  }

  .SecondLevelLabel {
    @apply self-stretch text-slate-700 text-sm font-body-1-normal leading-relaxed;
  }
  /* Tailwind CSS */
  .fixed-class {
    @apply sticky top-[0]; /* 或使用 fixed 依需求调整 */
    transform: translateY(3vh); /* 与视口的中心对齐 */
    /* 添加动画效果 */
    @apply transition-all duration-500 ease-in-out;
  }
  .relative-class {
    @apply relative;
  }
}

:root {
  --black: rgba(0, 0, 0, 1);
  --body-1-normal-font-family: "Jost-Regular", Helvetica;
  --body-1-normal-font-size: 16px;
  --body-1-normal-font-style: normal;
  --body-1-normal-font-weight: 400;
  --body-1-normal-letter-spacing: 0px;
  --body-1-normal-line-height: 150%;
  --gray-700: rgba(97, 97, 97, 1);
  --gray-800: rgba(32, 28, 55, 1);
  --headline-h1-font-family: "Inter-Bold", Helvetica;
  --headline-h1-font-size: 88px;
  --headline-h1-font-style: normal;
  --headline-h1-font-weight: 700;
  --headline-h1-letter-spacing: 0px;
  --headline-h1-line-height: 100%;
  --headline-h2-font-family: "Inter-Bold", Helvetica;
  --headline-h2-font-size: 28px;
  --headline-h2-font-style: normal;
  --headline-h2-font-weight: 700;
  --headline-h2-letter-spacing: 0px;
  --headline-h2-line-height: normal;
  --headline-h3-font-family: "Inter-SemiBold", Helvetica;
  --headline-h3-font-size: 24px;
  --headline-h3-font-style: normal;
  --headline-h3-font-weight: 600;
  --headline-h3-letter-spacing: 0px;
  --headline-h3-line-height: normal;
  --headline-h4-font-family: "Inter-SemiBold", Helvetica;
  --headline-h4-font-size: 20px;
  --headline-h4-font-style: normal;
  --headline-h4-font-weight: 600;
  --headline-h4-letter-spacing: 0px;
  --headline-h4-line-height: normal;
  --headline-h5-font-family: "Inter-Medium", Helvetica;
  --headline-h5-font-size: 18px;
  --headline-h5-font-style: normal;
  --headline-h5-font-weight: 500;
  --headline-h5-letter-spacing: 0px;
  --headline-h5-line-height: normal;
  --headlines-h1-font-family: "Inter-Bold", Helvetica;
  --headlines-h1-font-size: 88px;
  --headlines-h1-font-style: normal;
  --headlines-h1-font-weight: 700;
  --headlines-h1-letter-spacing: 0px;
  --headlines-h1-line-height: 100%;
  --headlines-h4-font-family: "Inter-SemiBold", Helvetica;
  --headlines-h4-font-size: 20px;
  --headlines-h4-font-style: normal;
  --headlines-h4-font-weight: 600;
  --headlines-h4-letter-spacing: 0px;
  --headlines-h4-line-height: normal;
  --neutral-grey: rgba(221, 220, 224, 1);
  --neutral-white: rgba(255, 255, 255, 1);
  --paragraph-p1-font-family: "Inter-Regular", Helvetica;
  --paragraph-p1-font-size: 18px;
  --paragraph-p1-font-style: normal;
  --paragraph-p1-font-weight: 400;
  --paragraph-p1-letter-spacing: 0px;
  --paragraph-p1-line-height: 179.99999523162842%;
  --paragraph-p2-font-family: "Inter-Regular", Helvetica;
  --paragraph-p2-font-size: 14px;
  --paragraph-p2-font-style: normal;
  --paragraph-p2-font-weight: 400;
  --paragraph-p2-letter-spacing: 0px;
  --paragraph-p2-line-height: 182.00000524520874%;
  --paragraph-p3-font-family: "Inter-SemiBold", Helvetica;
  --paragraph-p3-font-size: 14px;
  --paragraph-p3-font-style: normal;
  --paragraph-p3-font-weight: 600;
  --paragraph-p3-letter-spacing: 0px;
  --paragraph-p3-line-height: 182.00000524520874%;
  --paragraphs-p2-font-family: "Inter-Regular", Helvetica;
  --paragraphs-p2-font-size: 14px;
  --paragraphs-p2-font-style: normal;
  --paragraphs-p2-font-weight: 400;
  --paragraphs-p2-letter-spacing: 0px;
  --paragraphs-p2-line-height: 182.00000524520874%;
  --paragraphs-p3-font-family: "Inter-SemiBold", Helvetica;
  --paragraphs-p3-font-size: 14px;
  --paragraphs-p3-font-style: normal;
  --paragraphs-p3-font-weight: 600;
  --paragraphs-p3-letter-spacing: 0px;
  --paragraphs-p3-line-height: 182.00000524520874%;
  --primary-slate-200: rgba(182, 176, 211, 1);
  --primary-slate-300: rgba(140, 135, 168, 1);
  --primary-slate-700: rgba(63, 62, 94, 1);
  --primary-slate-750: rgba(47, 43, 82, 1);
  --primary-slate-800: rgba(42, 34, 79, 1);
  --primary-slate-900: rgba(31, 30, 51, 1);
  --slate-3: rgba(63, 62, 94, 1);
  --white: rgba(255, 255, 255, 1);
}
